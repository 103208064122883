
export default {
    model: {
        prop: 'checked',
        event: 'change'
    },
    props: {
        checked: Boolean
    },
    methods: {
        toggleCheck() {
            this.$emit('change', !this.checked);
        }
    }
};
